import { createInterFont } from '@tamagui/font-inter';
import { shorthands } from '@tamagui/shorthands';
import { tokens as tamaguiTokens, themes } from '@tamagui/themes';
import { createTamagui, createTokens } from 'tamagui';

const tokens = createTokens({
    ...tamaguiTokens,
    color: {
        ...tamaguiTokens.color,
        primary900: '#ffdbdb',
        primary500: '#8D1B15',
        primary400: '#52100d',
        secondary500: '#171923',
        secondary400: '#1a1a1c',
        secondary900: '#878cab',
    },
});

const appConfig = createTamagui({
    themes: {
        ...themes,
        light_primary_button: {
            ...themes.light_red_Button,
            background: tokens.color.primary900,
            borderColor: tokens.color.primary900,
        },
        light_secondary_button: {
            ...themes.light_blue_Button,
            background: tokens.color.secondary500,
            borderColorPress: '#50515c',
            backgroundPress: '#50515c',
            borderColor: tokens.color.secondary500,
            color: '#ffffff',
        },
    },
    tokens,
    shorthands,
    fonts: {
        heading: createInterFont(),
        body: createInterFont(),
    },
});

export type AppConfig = typeof appConfig;

declare module 'tamagui' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface TamaguiCustomConfig extends AppConfig {}
}

export default appConfig;
