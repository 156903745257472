import Constants from 'expo-constants';
import React, { useMemo } from 'react';
import { Text } from 'tamagui';
import { config } from '../../../../../config/env';

const AppVersion = () => {
    const channel = useMemo(() => {
        if (config.mode === 'production' || !config.mode) return '';

        return `-${config.mode}`;
    }, []);

    return (
        <Text color='#c8c8c8'>
            v{Constants.expoConfig?.version}
            {channel}
        </Text>
    );
};

export default AppVersion;
