import { EggsReportData } from '@/chicken-house/entities/reports/EggsReportData';
import { Report } from '@/reports/components';
import { formatBigNumber } from '@/shared/utils/number';
import React from 'react';
import { Stack } from 'tamagui';

type Props = {
    report: EggsReportData;
};

const EggReportResult = ({ report }: Props) => {
    function formatText(data: Record<'value' | 'totalBoxes', number> | undefined) {
        return `${formatBigNumber(data?.value || 0)} (${formatBigNumber(data?.totalBoxes || 0)} caixas)`;
    }

    return (
        <Stack>
            <Report.Card
                title={report.chickenHouseName}
                averageValueInPeriod={formatText(report.summary.averageFromPeriod)}
                minimumFromPeriod={{
                    title: 'Mínimo produzido no período',
                    value: formatText(report.summary.minimumFromPeriod),
                }}
                maximumFromPeriod={{
                    title: 'Máximo produzido no período',
                    value: formatText(report.summary.maximumFromPeriod),
                }}
                sumFromPeriod={{
                    title: 'Soma total no período',
                    value: formatText(report.summary.sumFromPeriod),
                }}
            />

            <Report.Chart
                averageValue={report.summary.averageFromPeriod.value}
                data={report.produced}
                maximumFromPeriod={report.summary.maximumFromPeriod}
                minimumFromPeriod={report.summary.minimumFromPeriod}
                hasBigNumbers
                stackProps={{ px: 8 }}
            />
        </Stack>
    );
};

export default EggReportResult;
