import { useQuery } from '@tanstack/react-query';
import { container } from '../../../../config/di';
import { FeedApi } from '../../api/FeedApi';
import { GetRationsResponse } from '../../api/types/FeedResponse';
import { sort } from 'fast-sort';

export function useFeedTypes() {
    const feedApi = container.resolve(FeedApi);

    return useQuery<GetRationsResponse>(
        ['feeds'],
        async () => {
            const feeds = await feedApi.getRations();

            return {
                ...feeds,
                // TODO: filter active
                // items: feeds.items.filter((item) => item.active),
                items: sort(feeds.items).asc((r) => r.order),
            };
        },
        {
            refetchOnWindowFocus: false,
        }
    );
}
