import merge from 'lodash.merge';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createAppStateStorage } from '../../../config/storage';
import { config } from '../../../config/env';

export type TesterSettings = {
    /**
     * Custom api url
     */
    apiUrl: string;

    /**
     * If true, it will use mocked data to show camers
     */
    shouldUseMockedChickenHouses: boolean;
};

type TesterSettingsStore = {
    isEnabled: boolean;
    settings: TesterSettings;
    onUpdateSetting: (settings: Partial<TesterSettings>) => void;
};

export const useTesterSettings = create(
    persist<TesterSettingsStore>(
        (set, get) => ({
            isEnabled: config.testerTools.enabled,

            settings: {
                shouldUseMockedChickenHouses: false,
                apiUrl: config.baseUrl,
            },

            onUpdateSetting(settings) {
                const current = get().settings;

                set({ settings: merge(current, settings) });
            },
        }),
        {
            name: 'alltis.tester-settings',
            storage: createJSONStorage(() => createAppStateStorage()),
            version: 1,
        }
    )
);
