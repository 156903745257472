import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

export function useLargeLayout() {
    const dimensions = useWindowDimensions();

    return useMemo(() => {
        return dimensions.width >= 953;
    }, [dimensions.width]);
}
