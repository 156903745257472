import { useQuery } from '@tanstack/react-query';
import { container } from '../../../../config/di';
import { SiloApi } from '../../api/SiloApi';

type Props = {
    refetchOnMount?: boolean;
};

export function useSilos(props?: Props) {
    const warehouseApi = container.resolve(SiloApi);

    return useQuery(['silos'], () => warehouseApi.getSilos(), {
        refetchOnMount: props?.refetchOnMount,
        cacheTime: Infinity,
    });
}
