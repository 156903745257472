import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { MultiSelectItem, MultiSelectProps } from './MultiSelectProps';
import Checkbox from '../Checkbox';
import { Stack } from 'tamagui';

type Props = MultiSelectProps;

const MultiSelect = (props: Props) => {
    const getValue = () => {
        return props.value
            ?.map((v) => props.items?.find((item) => item.value === v))
            .filter((item) => !!item) as MultiSelectItem[];
    };

    return (
        <Autocomplete
            multiple
            options={props.items || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Stack mr={6}>
                        <Checkbox style={{ marginRight: 8 }} value={selected} />
                    </Stack>

                    {option.label}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} variant='outlined' fullWidth label={props.label} placeholder={props.sheetTitle} />
            )}
            fullWidth
            onChange={(e, value) => props.onChange(value.map((i) => i.value))}
            value={getValue()}
        />
    );
};

export default MultiSelect;
