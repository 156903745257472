import { useAquaEnabled } from '@/aqua/hooks/flags/useAquaEnabled';
import Aqua from '@/aqua/screens/Aqua';
import { AquaManager } from '@/aqua/screens/AquaManager';
import PlaceHeader from '@/chicken-house/components/PlaceHeader';
import PlaceSetupIcon from '@/chicken-house/components/PlaceSetupIcon';
import PlaceEggs from '@/chicken-house/screens/PlaceEggs';
import AppLogo from '@/shared/components/ui/AppLogo';
import { WarehouseIcon } from '@/shared/components/ui/Icons/WarehouseIcon';
import Profile from '@/user/screens/Profile';
import WarehouseManager from '@/warehouse/screens/WarehouseManager';
import WarehouseTabs from '@/warehouse/screens/WarehousesTabs';
import { FontAwesome5 } from '@expo/vector-icons';
import Entypo from '@expo/vector-icons/Entypo';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useMemo } from 'react';
import { useTheme } from 'tamagui';
import { useLargeLayout } from '../../hooks/useLargeLayout';
import { useHomeTabsOptions } from './hooks/useHomeTabsOptions';

export type HomeTabsParams = {
    Aqua: undefined;
    Places: undefined;
    PlaceEggs: undefined;
    Warehouses: undefined;
    Profile: undefined;
};

const BottomTabs = createBottomTabNavigator<HomeTabsParams>();
const TopTabs = createMaterialTopTabNavigator<HomeTabsParams>();

export const HomeTabs = () => {
    const theme = useTheme();
    const isAquaEnabled = useAquaEnabled();

    const isLarge = useLargeLayout();

    const tabOptions = useHomeTabsOptions();

    const Tabs = useMemo(() => (isLarge ? TopTabs : BottomTabs), [isLarge]);

    return (
        <Tabs.Navigator
            screenOptions={() => ({
                tabBarActiveTintColor: theme.primary500.get() as string,
                tabBarHideOnKeyboard: true,
                headerTitle: () => <AppLogo />,
                headerTitleAlign: 'center',
                ...tabOptions,
            })}
            sceneContainerStyle={{
                paddingHorizontal: isLarge ? 24 : 0,
            }}
            initialRouteName='Places'
        >
            <Tabs.Screen
                name='Places'
                component={PlaceEggs}
                options={{
                    title: 'Aviários',
                    tabBarIcon: ({ color }) => <FontAwesome5 size={18} color={color} name='home' />,
                    headerTitle: () => <AppLogo mode='white' />,
                    headerStyle: {
                        backgroundColor: theme.secondary500.get() as string,
                        borderWidth: 0,
                        height: 98,
                    },
                    headerLeft: () => <PlaceHeader />,
                    headerRight: () => <PlaceSetupIcon color='light' />,
                    headerShadowVisible: false,
                }}
            />

            <Tabs.Screen
                name='Warehouses'
                component={isLarge ? WarehouseManager : WarehouseTabs}
                options={{
                    title: 'Silos',
                    tabBarIcon: ({ color }) => <WarehouseIcon size={23} color={color} />,
                    headerShadowVisible: false,
                }}
            />

            {isAquaEnabled && (
                <Tabs.Screen
                    name='Aqua'
                    component={isLarge ? AquaManager : Aqua}
                    options={{
                        title: 'Água',
                        tabBarIcon: ({ color }) => <Entypo name='water' size={25} color={color} />,
                    }}
                />
            )}

            {!isLarge && (
                <Tabs.Screen
                    name='Profile'
                    component={Profile}
                    options={{
                        title: 'Menu',
                        tabBarIcon: ({ color }) => <FontAwesome5 name='bars' size={18} color={color} />,
                        headerShadowVisible: false,
                        headerTitleAlign: 'left',
                    }}
                />
            )}
        </Tabs.Navigator>
    );
};
