import React from 'react';
import { Stack, Text } from 'tamagui';
import { BaseCard } from '../../shared/components/ui/BaseCard';
import LabelAndValue from '@/shared/components/ui/LabelAndValue';

type Props = {
    title: string;
    averageValueInPeriod: string;
    minimumFromPeriod: {
        title: string;
        value: string;
    };
    maximumFromPeriod: {
        title: string;
        value: string;
    };
    sumFromPeriod: {
        title: string;
        value: string;
    };
};

const ReportResultCard = ({ averageValueInPeriod, maximumFromPeriod, minimumFromPeriod, sumFromPeriod, title }: Props) => {
    return (
        <Stack p={14}>
            <BaseCard mb={12} cursor='initial'>
                <Text color='$secondary500' mb={14} fontSize={14} fontWeight='600'>
                    {title}
                </Text>

                <Stack flexDirection='row' $platform-native={{ flexDirection: 'column' }} gap={14} flexWrap='wrap'>
                    <LabelAndValue label='Média do Período' value={averageValueInPeriod} containerProps={{ flex: 1 }} />

                    <LabelAndValue label={minimumFromPeriod.title} value={minimumFromPeriod.value} containerProps={{ flex: 1 }} />

                    <LabelAndValue label={maximumFromPeriod.title} value={maximumFromPeriod.value} containerProps={{ flex: 1 }} />

                    <LabelAndValue label={sumFromPeriod.title} value={sumFromPeriod.value} containerProps={{ flex: 1 }} />
                </Stack>
            </BaseCard>
        </Stack>
    );
};

export default ReportResultCard;
