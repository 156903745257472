import WebProvider from '@/shared/components/providers/WebProvider';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { PortalProvider } from '@gorhom/portal';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context';
import { TamaguiProvider } from 'tamagui';
import { CacheProvider } from './src/config/cache';
import './src/config/locale';
import './src/config/logging';
import { ErrorHandlerProvider } from './src/config/logging/ErrorHandlerProvider';
import './src/config/orientation';
import tamaguiConfig from './src/config/theme/tamagui.config';
import { useInitialAppLoading } from './src/hooks/useInitialAppLoading';
import { AppStackNavigator } from './src/navigation/AppStack';
import { NavigationContainer } from './src/navigation/NavigationContainer';

function App() {
    const { onNavigationReady } = useInitialAppLoading();

    return (
        <ErrorHandlerProvider>
            <GestureHandlerRootView style={{ flex: 1 }}>
                <PortalProvider>
                    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
                        <NavigationContainer onReady={onNavigationReady}>
                            <TamaguiProvider config={tamaguiConfig} defaultTheme='light'>
                                <WebProvider>
                                    <BottomSheetModalProvider>
                                        <CacheProvider>
                                            <AppStackNavigator />
                                        </CacheProvider>
                                    </BottomSheetModalProvider>
                                </WebProvider>
                            </TamaguiProvider>
                        </NavigationContainer>
                    </SafeAreaProvider>
                </PortalProvider>
            </GestureHandlerRootView>
        </ErrorHandlerProvider>
    );
}

export default App;
