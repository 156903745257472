import { useQuery } from '@tanstack/react-query';
import { container } from '../../../../config/di';
import { SiloApi } from '../../api/SiloApi';

export function useSilosByGroup(groupId: string | null | undefined) {
    const siloApi = container.resolve(SiloApi);

    return useQuery(['silos-by-group', groupId], () => siloApi.getSilosByGroup(groupId!), {
        enabled: !!groupId,
    });
}
