import * as Navigation from '@react-navigation/native';
import { ComponentProps, useCallback } from 'react';
import { useLogger } from '../config/logging/useLogger';
import { routingInstrumentation } from '../config/logging/sentry';

type Props = Exclude<ComponentProps<typeof Navigation.NavigationContainer>, 'onStateChange'>;

export function NavigationContainer(props: Props) {
    const navigationContainerRef = Navigation.useNavigationContainerRef();

    const logger = useLogger();

    const onNavigationStateChange = useCallback(() => {
        const currentRoute = navigationContainerRef.getCurrentRoute();

        logger.setTag('screen', currentRoute?.name);

        logger.addBreadcrumb({
            category: 'navigation',
            message: `User navigated to screen ` + currentRoute?.name,
            timestamp: new Date().getTime(),
            data: currentRoute?.params,
        });
    }, []);

    const onReady = useCallback(() => {
        routingInstrumentation.registerNavigationContainer(navigationContainerRef);

        props.onReady?.();
    }, []);

    return (
        <Navigation.NavigationContainer
            {...props}
            documentTitle={{
                enabled: true,
                formatter: () => 'ALLTIS | IRIS',
            }}
            onStateChange={onNavigationStateChange}
            ref={navigationContainerRef}
            onReady={onReady}
            linking={{
                prefixes: ['localhost:19006'],
                config: {
                    initialRouteName: 'Home',
                    screens: {
                        Home: {
                            screens: {
                                Places: '/eggs',
                                Warehouses: '/silos',
                                Aqua: '/aqua',
                                Profile: '/profile',
                            },
                        },
                        Login: '/login',
                        AquaDetails: '/aqua/details',
                        EggsReport: '/eggs/report',
                        FeedManager: '/silos/ration',
                        FeedRegister: '/silos/ration/register/:rationId?',
                        WarehouseReport: '/silos/report',
                        Settings: '/settings',
                        SettingFieldScreen: '/settings/field',
                        PrivacyPolicy: '/privacy-policy',
                        WarehouseDetails: '/silos/details',
                        ReportExport: '/report/export/:type',
                    },
                },
            }}
        >
            {props.children}
        </Navigation.NavigationContainer>
    );
}
