import { CameraServerNetwork } from '@/chicken-house/entities/CameraServerNetwork';
import { createURL } from '@/chicken-house/utils/network';
import axios from 'axios';

export function useTestServerConnection() {
    async function isReachable({ ip, port }: CameraServerNetwork) {
        try {
            await axios({
                url: createURL({
                    protocol: 'http',
                    host: ip,
                    port,
                    path: '/scan/status',
                }),
                timeout: 5000,
            });

            return true;
        } catch (err) {
            return false;
        }
    }

    return { isReachable };
}
