import * as Application from 'expo-application';
import { Platform } from 'react-native';
import semver from 'semver';

/**
 * Checks if native application version is greater than or equal specified value
 *
 * @param version - Value to check with current native version
 */
export function minBuildVersion(version: string, allowWhenDEV = true): boolean {
    if (Platform.OS === 'web') return true;

    const hasMinVersion = semver.gte(Application.nativeApplicationVersion || '', version);

    if (hasMinVersion) return true;

    if (__DEV__) {
        return allowWhenDEV;
    }

    return false;
}
