import { ScrollableVictoryChart } from '@/shared/components/ui/ScrollableChart';
import { abbreviation, formatBigNumber, toFixedOrNot } from '@/shared/utils/number';
import dayjs from 'dayjs';
import React from 'react';
import { Platform } from 'react-native';
import { StackProps, useTheme } from 'tamagui';
import { VictoryAxis, VictoryBar, VictoryLegend, VictoryLine, VictoryScatter } from 'victory-native';

export type ReportValueInfo = { date: Date; value: number };

type Props = {
    data: Array<ReportValueInfo>;
    averageValue: number;
    minimumFromPeriod?: ReportValueInfo;
    maximumFromPeriod?: ReportValueInfo;
    valuePrefix?: string;
    hasBigNumbers?: boolean;
    stackProps?: StackProps;
};

const fontSize = Platform.select({ web: 14, native: 12 });

const BAR_WIDTH = 80;

const ReportChart = ({
    valuePrefix = '',
    averageValue,
    data,
    minimumFromPeriod,
    maximumFromPeriod,
    hasBigNumbers,
    stackProps,
}: Props) => {
    const { red11, green11 } = useTheme();

    const consumptionData = data.map((d) => d.value);

    const formatDateLabel = (date: Date | undefined) => {
        if (!date) return null;

        return dayjs(date).format('DD/MM');
    };

    const horizontalLabels = data.map((c) => formatDateLabel(c.date));

    const prefix = valuePrefix;

    const chartData = consumptionData.map((quantity, index) => ({
        x: horizontalLabels[index],
        y: quantity,
    }));

    const minDateLabel = formatDateLabel(minimumFromPeriod?.date);
    const maxDateLabel = formatDateLabel(maximumFromPeriod?.date);

    const formatValue = (value: number, abbr = false) => {
        if (abbr) {
            return abbreviation(value);
        }

        const formatted = hasBigNumbers ? formatBigNumber(value) : toFixedOrNot(value, 1);

        return formatted + prefix;
    };

    return (
        <ScrollableVictoryChart
            mainContent={
                <VictoryBar
                    data={chartData}
                    labels={consumptionData.map((value) => formatValue(value))}
                    style={{
                        labels: { fontSize },
                        data: {
                            fill: 'rgba(66, 153, 225, 0.3)',
                        },
                    }}
                />
            }
            legend={
                <VictoryLegend
                    orientation='horizontal'
                    x={40}
                    data={[
                        { name: 'Consumo no Período', symbol: { fill: '#95cfff' } },
                        { name: 'Média no Período', symbol: { fill: '#E9AD5B' } },
                    ]}
                    style={{ labels: { fontSize } }}
                />
            }
            yAxis={
                <VictoryAxis
                    // @ts-ignore
                    data={consumptionData.map((quantity, index) => ({
                        x: horizontalLabels[index],
                        y: quantity,
                    }))}
                    dependentAxis
                    offsetY={100}
                    tickFormat={(value) => formatValue(value, true)}
                    style={{ tickLabels: { fontSize } }}
                />
            }
            xAxis={
                <VictoryAxis
                    style={{
                        tickLabels: {
                            angle: -45,
                            fill: ({ text }) => {
                                const isMax = text === maxDateLabel;
                                const isMin = text === minDateLabel;

                                return isMax ? green11.get() : isMin ? red11.get() : '#444444';
                            },
                            fontSize,
                        },
                    }}
                />
            }
            size={{
                width: BAR_WIDTH * consumptionData.length,
            }}
            stackProps={stackProps}
            chartProps={{}}

            // TODO: web chart tooltip
            // chartProps={{
            //     containerComponent: Platform.select({
            //         web: (
            //             <VictoryVoronoiContainer
            //                 mouseFollowTooltips
            //                 voronoiDimension='x'
            //                 labels={({ datum }) => `y: ${datum.y}`}
            //             />
            //         ),
            //     }),
            // }}
        >
            <VictoryLine
                data={chartData.map((axis) => ({
                    x: axis.x,
                    y: averageValue,
                }))}
                style={{ data: { stroke: '#E9AD5B' } }}
                domainPadding={{ x: 0, y: 0 }}
            />

            <VictoryScatter
                style={{ data: { fill: '#ffffff', stroke: '#E9AD5B', strokeWidth: 1.5 } }}
                size={3.5}
                data={chartData.map((axis) => ({
                    x: axis.x,
                    y: averageValue,
                }))}
            />
        </ScrollableVictoryChart>
    );
};

export default ReportChart;
