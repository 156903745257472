import React, { ComponentProps, PropsWithChildren, ReactElement, cloneElement } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Stack, StackProps } from 'tamagui';
import { VictoryChart, VictoryTheme } from 'victory-native';
import { useLargeLayout } from '../../../../../hooks/useLargeLayout';
import { useChartWidth } from './useChartWidth';

type Props = PropsWithChildren<{
    mainContent: ReactElement;
    yAxis?: ReactElement;
    xAxis?: ReactElement;
    legend?: ReactElement;
    size?: {
        width?: number;
        height?: number;
    };
    chartProps?: Omit<ComponentProps<typeof VictoryChart>, 'width' | 'height'>;
    stackProps?: StackProps;
}>;

export const ScrollableVictoryChart = (props: Props) => {
    const isLargeScreen = useLargeLayout();
    const dimensions = useWindowDimensions();

    const chartWidth = useChartWidth(props.size?.width);

    const Chart = (
        <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={{
                x: Platform.select({
                    native: 30,
                    web: 60,
                }),
                y: 0,
            }}
            width={chartWidth}
            {...props.chartProps}
        >
            {isLargeScreen && props.yAxis}

            {isLargeScreen && props.legend}

            {props.xAxis}

            {props.children}

            {props.mainContent}
        </VictoryChart>
    );

    return (
        <Stack position='relative' {...props.stackProps}>
            {!isLargeScreen && (
                <Stack position='absolute' pointerEvents='none'>
                    <VictoryChart theme={VictoryTheme.material} width={dimensions.width}>
                        {props.legend}

                        {props.yAxis}

                        {cloneElement(props.mainContent, {
                            style: { data: { display: 'none' } },
                            labels: [],
                        })}
                    </VictoryChart>
                </Stack>
            )}

            {!isLargeScreen ? (
                <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                    {Chart}
                </ScrollView>
            ) : (
                Chart
            )}
        </Stack>
    );
};
