import { WarehouseIcon } from '@/shared/components/ui/Icons/WarehouseIcon';
import MultiSelect from '@/shared/components/ui/MultiSelect';
import { MultiSelectProps } from '@/shared/components/ui/MultiSelect/MultiSelectProps';
import { useSilos } from '@/warehouse/hooks/silos/useSilos';
import React from 'react';

type Props = Pick<MultiSelectProps, 'onChange' | 'value'>;

const WarehouseSelect = (props: Props) => {
    const { data } = useSilos();

    return (
        <MultiSelect
            items={data?.silos.map((item) => ({
                label: item.name,
                value: item.id,
                caption: item.site?.name || '-',
            }))}
            icon={
                <>
                    <WarehouseIcon size={20} color='#CBD5E0' />
                </>
            }
            label='Selecionar os silos'
            {...props}
        />
    );
};

export default WarehouseSelect;
