import { Report } from '@/reports/components';
import { WarehouseReportForm } from '@/warehouse/entities/WarehouseReportForm';
import { useWarehouseReport } from '@/warehouse/hooks/report/useWarehouseReport';
import React from 'react';
import WarehouseReportResults from '../WarehouseReportResults';

type Props = {
    filter: WarehouseReportForm;
};

const SiloReportContent = ({ filter }: Props) => {
    const report = useWarehouseReport(filter as WarehouseReportForm);

    const hasSilo = !!filter.warehouseIds?.length;

    return (
        <Report.Content
            title='Relatório dos Silos'
            options={{
                empty: !report.data?.length || !hasSilo,
                error: report.isError,
                loading: report.isLoading && hasSilo,
            }}
        >
            <Report.List data={report?.data} renderItem={({ item }) => <WarehouseReportResults report={item} />} />
        </Report.Content>
    );
};

export default SiloReportContent;
