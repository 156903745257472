import React, { PropsWithChildren, ReactElement } from 'react';
import { Stack, StackProps, Text } from 'tamagui';

export type FieldBaseProps = PropsWithChildren<{
    icon?: ReactElement;
    containerProps?: StackProps;
    helperText?: string;
}>;

const FieldBase = ({ containerProps, icon, ...props }: FieldBaseProps) => {
    return (
        <Stack {...containerProps}>
            <Stack borderWidth={1} borderColor='#CBD5E0' jc='center' ai='center' fd='row' borderRadius={6} px={14} minHeight={52}>
                {icon && <Stack w={18}>{React.cloneElement(icon || <></>, { color: '#CBD5E0', size: 16 })}</Stack>}

                <Stack flex={1} ml={icon ? 10 : 0}>
                    {props.children}
                </Stack>
            </Stack>

            {props.helperText && (
                <Text color='#A0AEC0' fontSize={12} mt='$2'>
                    {props.helperText}
                </Text>
            )}
        </Stack>
    );
};

export default FieldBase;
