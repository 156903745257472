import { Feather } from '@expo/vector-icons';
import React, { ComponentProps, ReactNode } from 'react';
import { RectButton } from 'react-native-gesture-handler';
import { Stack, Text, XStack, useTheme } from 'tamagui';
import Spinner from '../../Spinner';

type Props = {
    title: string;
    icon: ComponentProps<typeof Feather>['name'];
    onPress?: () => void;
    disabled?: boolean;
    loading?: boolean;
    rightContent?: ReactNode;
};

const SectionItem = (props: Props) => {
    const theme = useTheme();

    return (
        <RectButton
            onPress={props.onPress}
            enabled={!props.disabled}
            style={{
                // @ts-ignore
                cursor: 'pointer',
            }}
        >
            <XStack
                ai='center'
                opacity={props.disabled ? 0.4 : 1}
                px={22}
                cursor='pointer'
                py={18}
                hoverStyle={{
                    bg: '#f4f6f9',
                }}
            >
                <XStack ai='center' flex={1}>
                    <Feather name={props.icon} size={22} />

                    <Text ml={12} fontWeight='600'>
                        {props.title}
                    </Text>
                </XStack>

                <Stack maxWidth='60%'>
                    {props.loading ? (
                        <Spinner size='small' color='#8d8d8d' />
                    ) : props.rightContent ? (
                        <Stack pointerEvents='none'>{props.rightContent}</Stack>
                    ) : (
                        <Feather name='chevron-right' size={18} color={theme.secondary900.get() as string} />
                    )}
                </Stack>
            </XStack>
        </RectButton>
    );
};

export default SectionItem;
