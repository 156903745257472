import * as Updates from 'expo-updates';
import { Env } from './types';

function getEnvMode(): Env {
    if (__DEV__) return 'dev';

    return (Updates.channel || process.env.EXPO_PUBLIC_TARGET_ENV || 'staging') as Env;
}

const mode = getEnvMode();

export const config = {
    mode,
    baseUrl: process.env.EXPO_PUBLIC_API_BASE_URL || 'https://iris-staging-server.tail077cf.ts.net',
    testerTools: {
        enabled: Boolean(Number(process.env.EXPO_PUBLIC_SHOW_TESTER_TOOLS)) || mode === 'staging',
    },
    database: {
        mmkv: {
            encryptionKey: process.env.EXPO_PUBLIC_MMKV_ENCRYPTION_KEY || '06c9e683-a742-4750-925b-a0ed1b4c4f17',
        },
    },
};
