import React, { ComponentProps } from 'react';
import { MaterialTabBarProps } from 'react-native-collapsible-tab-view';
import { NavigationState, TabBar as RNTabBar, Route, SceneRendererProps, TabBarProps } from 'react-native-tab-view';
import { useTheme } from 'tamagui';
import { useLargeLayout } from '../../../../../hooks/useLargeLayout';
import { MaterialTabBar } from './MaterialTabs';

type Props<T extends Route | string> = (T extends Route
    ? SceneRendererProps & { navigationState: NavigationState<T> }
    : MaterialTabBarProps<string> & { isCollapsable: true }) & {
    colorMode?: 'dark' | 'light';
    renderIcon?: ComponentProps<typeof RNTabBar>['renderIcon'];
};

function TabBar(props: Props<Route | string>) {
    const { colorMode = 'light' } = props;

    const isLargeLayout = useLargeLayout();

    const isLight = colorMode === 'light' || isLargeLayout;

    const theme = useTheme();

    if ('isCollapsable' in props && MaterialTabBar) {
        return (
            <MaterialTabBar
                {...props}
                indicatorStyle={{ backgroundColor: theme.primary500.get() as string }}
                inactiveColor='#A0AEC0'
                activeColor={theme.primary500.get() as string}
                style={[props.style, { height: 50 }]}
                tabStyle={isLargeLayout ? { width: 150 } : undefined}
            />
        );
    }

    return (
        <RNTabBar
            {...(props as TabBarProps<Route>)}
            style={{ backgroundColor: isLight ? '#ffffff' : theme.secondary500.get() }}
            indicatorStyle={{ backgroundColor: isLight ? (theme.primary500.get() as string) : '#ffffff' }}
            inactiveColor='#A0AEC0'
            activeColor={isLight ? (theme.primary500.get() as string) : '#ffffff'}
            renderIcon={isLargeLayout ? undefined : props.renderIcon}
            tabStyle={isLargeLayout ? { width: 150 } : undefined}
        />
    );
}

export default TabBar;
