import { useCallback, useState } from 'react';
import { MultiSelectItem } from '../../MultiSelect/MultiSelectProps';

export function useMultipleFilterState(defaultValue?: string[]) {
    const [selectedValue, setSelectedValue] = useState<string[]>(defaultValue || []);

    const handleSelectValue = useCallback((item: MultiSelectItem) => {
        setSelectedValue((selected) => {
            const isSelected = selected.includes(item.value);

            if (isSelected) {
                return selected.filter((v) => item.value !== v);
            }

            return [...selected, item.value];
        });
    }, []);

    return {
        value: selectedValue,
        onSelect: handleSelectValue,
    };
}
