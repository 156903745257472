import { WarehouseStateIcon } from '@/warehouse/components/WarehouseStateIcon';
import { Silo } from '@/warehouse/entities/Silo';
import { useQuantityUnits } from '@/warehouse/hooks/useQuantityUnits';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import React from 'react';
import { Stack, Text, XStack } from 'tamagui';

type Props = {
    silo: Pick<Silo, 'prefer_tons' | 'name' | 'level' | 'temperature'>;
    temperaturePosition?: 'right' | 'bottom';
};

const WarehouseHeaderInfo = ({ silo, temperaturePosition = 'right' }: Props) => {
    const quantityUnits = useQuantityUnits(silo?.prefer_tons);

    return (
        <Stack flexDirection={temperaturePosition === 'right' ? 'row' : 'column'}>
            <Stack ai='center'>
                <Stack>
                    <Text fontWeight='600' color='$secondary900' mb={10} fontSize={14}>
                        {silo.name}
                    </Text>
                </Stack>

                <WarehouseStateIcon
                    label={quantityUnits.format({
                        perc: silo?.level.current.percentage,
                        tons: silo?.level.current.tons,
                    })}
                    percentage={(silo?.level.current.linear || 0) * 100}
                    status={silo?.level.status}
                />
            </Stack>

            <XStack
                justifyContent='center'
                alignItems='center'
                ml={temperaturePosition === 'right' ? 14 : 0}
                mt={temperaturePosition === 'right' ? 0 : 12}
            >
                <FontAwesome5 name='temperature-high' size={14} color='#a1a5ab' />

                <Text color='#a1a5ab' fontWeight='600' ml={4} fontSize={14}>
                    {silo?.temperature || 0} ºC
                </Text>
            </XStack>
        </Stack>
    );
};

export default WarehouseHeaderInfo;
