import React, { Children, PropsWithChildren } from 'react';
import { Separator, Stack, Text } from 'tamagui';
import SectionItem from './SectionItem';

type Props = PropsWithChildren<{
    name: string;
}>;

const Section = (props: Props) => {
    return (
        <Stack my={16}>
            <Text mb={8} color='$secondary900' px={22}>
                {props.name}
            </Text>

            <Stack>
                {Children.map(props.children, (child, index) => (
                    <React.Fragment key={index}>
                        {child}
                        <Separator />
                    </React.Fragment>
                ))}
            </Stack>
        </Stack>
    );
};

Section.Item = SectionItem;

export default Section;
