/**
 * Handle decimal value and add decimal places only if necessary
 *
 * @param value - Value to be handle
 * @param decimalPlaces - Number of decimal places
 * @returns formatted value
 */
export function toFixedOrNot(value: number, decimalPlaces: number) {
    return (value || 0).toFixed(decimalPlaces).replace(/\.0?0$/, '');
}

/**
 * Handle big numbers to easily reading
 *
 * @param value - Value to be formatted
 * @returns formatted value
 */
export function formatBigNumber(value: number) {
    return Intl.NumberFormat('pt', { notation: 'standard' }).format(value || 0);
}

/**
 * Abbreviate numbers
 * @param num - Number to abbreviate
 * @returns abbreviate number (10k, 20b)
 */
export function abbreviation(num: number) {
    if (num >= 1e9) {
        return toFixedOrNot(num / 1e9, 1) + 'b';
    } else if (num >= 1e6) {
        return toFixedOrNot(num / 1e6, 1) + 'm';
    } else if (num >= 1e3) {
        return toFixedOrNot(num / 1e3, 1) + 'k';
    } else {
        return num.toString();
    }
}
