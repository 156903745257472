import { useLogout } from '@/authentication/hooks/useLogout';
import { usePrivacyPolicy } from '@/authentication/hooks/usePrivacyPolicy';
import Section from '@/shared/components/ui/Section';
import Guard from '@/shared/components/utils/Guard';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import { minBuildVersion } from '@/shared/utils/version';
import { useTesterSettings } from '@/user/store/TesterSettingsStore';
import React from 'react';
import { Stack } from 'tamagui';
import TesterSettings from './TesterSettings';
import { useAuth } from '@/authentication/store/AuthStore';
import { Platform } from 'react-native';

const Menu = () => {
    useStatusBar('dark-content');

    const logout = useLogout();

    const privacyPolicy = usePrivacyPolicy();

    const enableTesterSettings = useTesterSettings((state) => state.isEnabled);

    const isLogged = useAuth((state) => !!state.auth?.token);

    return (
        <Stack>
            <Guard isEnabled={minBuildVersion('1.15.0')}>
                <Section name='Termos e condições'>
                    <Section.Item title='Política de Privacidade' icon='shield' onPress={() => privacyPolicy.open()} />
                </Section>
            </Guard>

            {enableTesterSettings && <TesterSettings />}

            {isLogged && (
                <Section name='Minha conta'>
                    <Section.Item
                        title='Sair da Conta'
                        icon='log-out'
                        onPress={() => logout.handleLogout({ hasConfirmation: Platform.OS !== 'web' })}
                        loading={logout.isLoading}
                    />
                </Section>
            )}
        </Stack>
    );
};

export default Menu;
