import { Report } from '@/reports/components';
import SiloReportContent from '@/warehouse/components/WarehouseReport/SiloReportContent';
import WarehouseSelect from '@/warehouse/components/WarehouseSelect';
import { WarehouseReportForm } from '@/warehouse/entities/WarehouseReportForm';
import { useSiloPanel } from '@/warehouse/store/useSiloPanelStore';
import { RouteProp, useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Stack } from 'tamagui';
import { AppStackParamsList } from '../../../../navigation/AppStack';

const WarehouseReport = () => {
    const { params } = useRoute<RouteProp<AppStackParamsList, 'WarehouseReport'>>();

    const panel = useSiloPanel();

    const activeSilos = useMemo(() => {
        if (panel.currentTab === 'reports' && panel.selectedSiloId) {
            return [panel.selectedSiloId];
        }

        return params?.silo?.id ? [params?.silo?.id] : [];
    }, [params?.silo?.id, panel.currentTab, panel.selectedSiloId]);

    const warehouseReportForm = useForm<WarehouseReportForm>({
        defaultValues: {
            startDate: dayjs().subtract(8, 'days').startOf('day').toDate(),
            endDate: dayjs().endOf('day').toDate(),
            warehouseIds: activeSilos,
        },
    });

    useEffect(() => {
        warehouseReportForm.setValue('warehouseIds', activeSilos);
    }, [activeSilos]);

    const filter = useWatch({ control: warehouseReportForm.control });

    return (
        <Stack flex={1}>
            <FormProvider {...warehouseReportForm}>
                <Report.Form control={warehouseReportForm.control}>
                    <Controller
                        name='warehouseIds'
                        render={({ field: { value, onChange } }) => <WarehouseSelect onChange={onChange} value={value} />}
                        control={warehouseReportForm.control}
                    />
                </Report.Form>

                <SiloReportContent filter={filter as WarehouseReportForm} />

                <Report.ExportButton
                    filter={{
                        entityType: 'warehouse',
                        entityIds: filter.warehouseIds,
                        startDate: filter.startDate,
                        endDate: filter.endDate,
                    }}
                />
            </FormProvider>
        </Stack>
    );
};

export default WarehouseReport;
