import { Popover as MuiPopover } from '@mui/material';
import React, { useContext } from 'react';
import { PopoverTriggerProps } from '../../PopoverProps';
import { PopoverContext } from '../../context/PopoverContext';

const PopoverContent = (props: PopoverTriggerProps) => {
    const popoverContext = useContext(PopoverContext);

    const open = Boolean(popoverContext.anchor);

    const handleClose = () => {
        popoverContext.setAnchor(null);
    };

    return (
        <MuiPopover
            anchorEl={popoverContext.anchor}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            {...popoverContext.props}
        >
            {props.children}
        </MuiPopover>
    );
};

export default PopoverContent;
