import { useCameraSetupEnabled } from '@/chicken-house/hooks/permissions/useCameraSetupEnabled';
import { useChickenHouses } from '@/chicken-house/hooks/places/useChickenHouses';
import { useServerScan } from '@/chicken-house/hooks/server-scan/useServerScan';
import { useCameraServer } from '@/chicken-house/store/CameraServerStore';
import ViewState from '@/shared/components/ui/ViewState';
import React from 'react';
import { Stack } from 'tamagui';
import StepAlerts from '../PlaceScan/StepAlerts';
import PlacesList from '../PlacesList';

const TEN_SECONDS = 10000;

const PlaceInformation = () => {
    const { isScanning } = useServerScan({ scanOnMount: true });

    const shouldUseRemote = useCameraServer((store) => store.networkConfig?.shouldUseRemoteServer);

    const chickenHouses = useChickenHouses({ refreshInterval: TEN_SECONDS });

    const isCameraSetupEnabled = useCameraSetupEnabled();

    if (isScanning && !shouldUseRemote) {
        return (
            <Stack flex={1}>
                <StepAlerts animationHeight={130} hideCaption isSimple />
            </Stack>
        );
    }

    return (
        <Stack flex={1}>
            <PlacesList
                ListEmptyComponent={
                    <ViewState
                        empty={{
                            title: 'Nenhum aviário configurado',
                            message: isCameraSetupEnabled
                                ? 'Faça a configuração dos aviários para ver as câmeras'
                                : 'Solicite ao seu administrador a configuração das câmeras do núcleo',
                            enabled: chickenHouses.data?.length === 0,
                        }}
                        loading={chickenHouses.isLoading}
                        error={chickenHouses.isError}
                    />
                }
            />
        </Stack>
    );
};

export default PlaceInformation;
