import { SiloLevelStatus } from '@/warehouse/entities/WarehouseLevel';
import { useCallback, useId } from 'react';
import { ClipPath, Defs, G, Line, Path, Rect, Svg, Text } from 'react-native-svg';

type Props = {
    label: string;
    percentage: number;
    status?: SiloLevelStatus;
    numberOfPanels?: number;
};

const options = {
    container: {
        width: 220,
        height: 110,
    },
    top: {
        height: 52,
    },
    content: {
        height: 91,
    },
};

export function WarehouseStateIcon({ status, percentage, label, numberOfPanels = 0 }: Props) {
    const clipPathId = useId();

    function getPanels() {
        if (!numberOfPanels) return [];

        const resultado: number[] = [0];
        const interval = options.content.height / numberOfPanels;

        for (let i = 1; i <= numberOfPanels; i++) {
            resultado.push(interval * i);
        }

        return resultado;
    }

    function getFill() {
        if (status === 'ALERT') {
            return '#e95b5b';
        }

        return '#E9AD5B';
    }

    const SiloTop = useCallback(() => <Path id='Vector_2' d='M55 4L105 46.7095V48.9273H5V46.7095L55 4Z' y={1} />, []);

    const SiloContent = useCallback(() => <Path id='Vector' d='M103 49H7V147.687H103V49Z' />, []);

    const SiloBottom = useCallback(
        () => (
            <>
                <Path id='Vector_3' d='M54.9995 214.796L7 147H103L54.9995 214.796Z' />
                <Path d='M13 146C13 143.791 11.2091 142 9 142C6.79086 142 5 143.791 5 146H13ZM5 216V220H13V216H5ZM5 146V216H13V146H5Z' />
                <Path d='M105 146C105 143.791 103.209 142 101 142C98.7909 142 97 143.791 97 146H105ZM97 216V220H105V216H97ZM97 146V216H105V146H97Z' />
            </>
        ),
        []
    );

    return (
        <Svg
            viewBox={`0 0 ${options.container.height} ${options.container.width}`}
            fill='none'
            height={160}
            width={75}
            key={percentage}
        >
            <Defs>
                <ClipPath id={clipPathId}>
                    <Rect
                        id='fillBox'
                        x='0'
                        y='-150'
                        width='100%'
                        height={(percentage || 0) + '%'}
                        transform='rotate(180, 55, 35)'
                    />
                </ClipPath>
            </Defs>

            <G id='silo_new'>
                <G id='BG' fill='#cccccc' stroke='#cccccc' strokeWidth={7}>
                    <SiloTop />
                    <SiloContent />
                    <SiloBottom />
                </G>

                <G id='Fill' fill={getFill()} clipPath={`url(#${clipPathId})`}>
                    <SiloTop />
                    <SiloContent />
                    <SiloBottom />
                </G>

                <G y={options.top.height}>
                    {getPanels().map((val) => (
                        <Line
                            y1={val}
                            y2={val}
                            x2={options.container.width - 115.5}
                            x1='7'
                            stroke='#af9168'
                            strokeWidth='2'
                            strokeDasharray='4,4'
                            key={val}
                        />
                    ))}
                </G>
            </G>

            <Text fill='#ffffff' fontSize={20} x='50%' y={104} textAnchor='middle' fontWeight={700}>
                {label}
            </Text>
        </Svg>
    );
}
