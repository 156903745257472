import { useQuery } from '@tanstack/react-query';
import { container } from '../../../config/di';
import { AuthApi } from '../api/AuthApi';

export function useGetUser() {
    const authApi = container.resolve(AuthApi);

    return useQuery({
        queryFn: () => authApi.getCurrentUser(),
    });
}
