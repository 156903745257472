import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Toaster } from 'burnt/web';
import { PropsWithChildren } from 'react';
import appConfig from '../../../../../config/theme/tamagui.config';

const theme = createTheme({
    palette: {
        primary: {
            '500': appConfig.tokens.color.primary500.val,
        },
    },
});

export default function WebProvider(props: PropsWithChildren) {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {props.children}

                <Toaster position='bottom-center' />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
