import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import { Text, XStack, useTheme } from 'tamagui';

export type FilterIndicatorProps = {
    label: string;
    active?: boolean;
};

const FilterIndicator = ({ label, active }: FilterIndicatorProps) => {
    const theme = useTheme();

    return (
        <XStack
            borderRadius={999}
            borderWidth={0.5}
            borderColor={active ? '$primary900' : '#CBD5E0'}
            py={8}
            px={12}
            alignSelf='flex-start'
            jc='center'
            ai='center'
            cursor='pointer'
            bg={active ? '$primary900' : '#ffffff'}
            hoverStyle={{ backgroundColor: active ? '$primary900' : '#edf1f6' }}
        >
            <Text color={active ? '$primary400' : '$secondary900'} mr={6} fontFamily='$body' fontSize='$3'>
                {label}
            </Text>

            <FontAwesome5 name='chevron-down' color={active ? theme.primary400.get() : theme.secondary900.get()} />
        </XStack>
    );
};

export default FilterIndicator;
