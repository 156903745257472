import { handleResponseNumber } from '@/shared/utils/response';
import { WarehouseReportData } from '@/warehouse/entities/WarehouseReportData';
import { WarehouseReportForm } from '@/warehouse/entities/WarehouseReportForm';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { maxBy, minBy } from 'lodash';
import { alltisApiV2 } from '../../../../config/api/alltisApiV2';

type SiloHistoryResponse = {
    id: string;
    name: string;
    group_name: string;
    prefer_tons: true;
    historic: {
        percentage: 0;
        timestamp: string;
        tons: 0;
    }[];
    summary: {
        maximum: {
            timestamp: string;
            percentage: number;
            tons: number;
        };
        minimum: {
            timestamp: string;
            percentage: number;
            tons: number;
        };
        average: {
            percentage: number;
            tons: number;
        };
        sum?: {
            percentage: number;
            tons: number;
        };
    };
}[];

export function useWarehouseReport(filter: WarehouseReportForm) {
    return useQuery<WarehouseReportData[]>(
        ['silo-report', filter],
        async () => {
            const warehouses = filter.warehouseIds?.join(',') || '';

            const response = await alltisApiV2.get<SiloHistoryResponse>(`/silos/historical`, {
                params: {
                    ids: warehouses,
                    initial_period: filter.startDate,
                    final_period: filter.endDate,
                },
            });

            return (
                response.data?.map((data) => {
                    const isPercentage = !data.prefer_tons;

                    const report: WarehouseReportData = {
                        warehouseName: data.name,
                        conjugatedWarehouseName: data.group_name,
                        averageValueInPeriod: handleResponseNumber(
                            data.prefer_tons ? data.summary.average.tons : data.summary.average.percentage,
                            isPercentage
                        ),
                        unit: data.prefer_tons ? 'tons' : 'perc',
                        consumption: data.historic.map((c) => ({
                            date: dayjs(c.timestamp).toDate(),
                            value: handleResponseNumber(data.prefer_tons ? c.tons : c.percentage, !data.prefer_tons),
                        })),
                        sumFromPeriod: {
                            value: handleResponseNumber(
                                data.prefer_tons ? data.summary.sum?.tons : data.summary.sum?.percentage,
                                !data.prefer_tons
                            ),
                        },
                    };

                    report.minimumFromPeriod = minBy(report.consumption, (c) => c.value);
                    report.maximumFromPeriod = maxBy(report.consumption, (c) => c.value);

                    return report;
                }) || []
            );
        },
        {
            enabled: !!filter.warehouseIds?.length && !!filter.endDate && !!filter.startDate,
        }
    );
}
