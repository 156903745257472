import { Platform } from 'react-native';
import { YStack, styled } from 'tamagui';

export const BaseCard = styled(YStack, {
    borderRadius: 10,
    padding: 22,
    borderColor: '#A0AEC0',
    borderWidth: 0.5,
    '$platform-web': {
        borderWidth: 0.1,
    },
    cursor: 'pointer',
    ...(Platform.OS === 'web' && {
        hoverStyle: {
            bg: '#f4f6f9',
        },
    }),
});
