import { useWindowDimensions } from 'react-native';
import { useLargeLayout } from '../../../../../hooks/useLargeLayout';
import { usePanel } from '../Panel/hooks/usePanel';

export function useChartWidth(defaultWidth = 300) {
    const isLargeScreen = useLargeLayout();
    const dimensions = useWindowDimensions();
    const { isPanel } = usePanel();

    function getWidth() {
        if (isLargeScreen) return dimensions.width / (isPanel ? 2 : 1);

        if (!defaultWidth) return defaultWidth;

        if (defaultWidth < dimensions.width) {
            return dimensions.width;
        }

        return defaultWidth;
    }

    return getWidth();
}
